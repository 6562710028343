import React from 'react';
import './Cards.css';

export default function AboutCard({image, title, description}){
    return (
        <div className='about-card'>
            <div className='about-image-div'>
                <img src={image} alt='about-image'/>
                <div className='description-overlay'>
                <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
            </div>
            <h3>{title}</h3>
        </div>
    );
};