import React from 'react';
import './Buttons.css';
import { useTranslation } from 'react-i18next';

const LanguageButton = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language
    localStorage.setItem('language', lng); // Save the selected language to localStorage
  };

  return (
    <div className='lang-buttons'>
      {/* Show English button only if the current language is not English */}
      {i18n.language !== 'en' && (
        <img src='/assets/icons/uk-flag.png' alt='uk flag' onClick={() => changeLanguage('en')}/>
      )}

      {/* Show Arabic button only if the current language is not Arabic */}
      {i18n.language !== 'ar' && (
        <img src='/assets/icons/ksa-flag.png' alt='ksa flag' onClick={() => changeLanguage('ar')}/>
      )}
    </div>
  );
};

export default LanguageButton;