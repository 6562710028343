import React, { useState, useEffect } from 'react';
import './Footer.css';
import {  Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'


// API URL
const API_URL = "https://admin.proserveme.com/api/setting";

const Footer = () => {
    const { t } = useTranslation();
    const [settings, setSettings] = useState(null);
    
    useEffect(() => {
      const fetchSettings = async () => {
        try {
          const response = await fetch(API_URL);
          const result = await response.json();
          if (result.value) {
            setSettings(result.data);
          }
        } catch (error) {
          console.error("Error fetching settings:", error);
        }
      };
  
      fetchSettings();
    }, []);

  return (
    <footer dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
        <div  className='footer-upper'>
            <div className='footer-col-1'>
                <Link to='/' onClick={() => { window.scrollTo(0, 0); }}><img src={settings ? settings.header_logo : '/assets/icons/logo-color.png'} alt='logo'/></Link>
                <div dangerouslySetInnerHTML={{ __html: settings?i18n.language === 'ar' ? settings.footer_details.details_ar : settings.footer_details.details_en:"" }} />
            </div>

            <div className='footer-col-2'>
                <h3>{t("Company")}</h3>
                    <Link to='/' onClick={() => { window.scrollTo(0, 0); }}>{t("Home")}</Link>
                    <Link to='/about' onClick={() => { window.scrollTo(0, 0); }}>{t("About")}</Link>
                    <Link to='/services' onClick={() => { window.scrollTo(0, 0); }}>{t("Services")}</Link>
            </div>
            <div className='footer-col-3'>
                <h3>{t("Contact")}</h3>
                <Link to='mailto:info@proserveme.com'>{settings?settings.email:""}</Link>
            </div>
        </div>

        <div className='footer-lower'>{t("All rights reserved ")}<a href='https://unimarkme.com/' target="_blank" rel="noopener noreferrer">@unimarkme.com</a></div>
    </footer>
  );
};

export default Footer;
