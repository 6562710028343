import React, { useState, useEffect } from 'react';
import './Nav.css';
import i18n from '../../i18n'

import StrokedButton from '../buttons/StrokedButton';
import OrderCard from '../cards/OrderCard';
import LanguageButton from '../buttons/LanguageButton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// API URL
const API_URL = "https://admin.proserveme.com/api/setting";

const Nav = () => {
  const { t } = useTranslation();

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu
  const [settings, setSettings] = useState(null);  // New state for settings


  const handleOrderClick = () => {
    setShowOrderModal(true);
  };

  const handleCloseModal = () => {
    setShowOrderModal(false);
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu state
  };

    // Monitor scroll position
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 100) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      const fetchSettings = async () => {
        try {
          const response = await fetch(API_URL);
          const result = await response.json();
          if (result.value) {
            setSettings(result.data);
          }
        } catch (error) {
          console.error("Error fetching settings:", error);
        }
      };
  
      fetchSettings();
    }, []);
  
  return (
    <nav dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className={isSticky ? 'shrink' : ''}>
        <Link to='/'><img  className='logo' src={settings ? settings.header_logo : '/assets/icons/logo-color.png'} href='/' alt="Logo" onClick={() => { window.scrollTo(0, 0); }}/></Link>
        <div className='dots'>
        <div></div>
        <div></div>
        <div></div>
      </div>        
                <div className='lang-brgr'>
                <LanguageButton />

              {/* Hamburger Menu Icon */}
      <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      </div>
      {/* Navigation Links */}
      <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <li>
          <a href="/">{t("Home")}</a>
        </li>
        <li>
          <a href="/about">{t("About")}</a>
        </li>
        <li>
          <a href="/services">{t("Services")}</a>
        </li>
        <li>
          <a href="#contact-card">{t("Contact Us")}</a>
        </li>
        <li id='mobile-order'>
          <StrokedButton placeHolder={t('Order Now')} onClick={handleOrderClick}/>
        </li>
      </ul>

        <div className='nav-right'>
            <StrokedButton placeHolder={t('Order Now')} onClick={handleOrderClick}/>
            <LanguageButton />
            </div>
            {showOrderModal && (
            <div className="modal-backdrop" onClick={handleCloseModal}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <OrderCard onClose={handleCloseModal} />
                </div>
            </div>
        )}
    </nav>
  );
};

export default Nav;
