import React, { useState } from 'react';
import './Cards.css';
import SubmitButton from '../buttons/SubmitButton';
import StrokedButton from '../buttons/StrokedButton';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

export default function OrderCard({ onClose , preSelectedServiceId }) {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState('user'); // Tracks user/supplier selection
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        service: preSelectedServiceId || '', // Pre-select the service if provided
        message: '' // For supplier form
    });
    const [submissionStatus, setSubmissionStatus] = useState(null); // Tracks success/error

    // Handle toggle between user and supplier
    const handleToggleUser = () => {
        setToggle('user');
        setFormData({ name: '', phone: '', service: preSelectedServiceId || '', message: '' }); // Reset form data
    };

    const handleToggleSupplier = () => {
        setToggle('supplier');
        setFormData({ name: '', phone: '', service: '', message: '' }); // Reset form data
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
    
        // Set service_id to 6 by default if toggle is 'supplier'
        let serviceId = toggle === 'supplier' ? 6 : parseInt(formData.service, 10) || 1;
    
        // Build the correct payload for user/supplier
        const payload = {
            name: formData.name.trim(),
            phone: formData.phone.trim(),
            service_id: serviceId,
            type: toggle
        };
    
        // If the user is a supplier, include "message" in the payload
        if (toggle === 'supplier') {
            payload.message = formData.message.trim();
        }
    
        console.log('Sending Payload: ', payload); // Debugging
    
        try {
            const response = await fetch('https://admin.proserveme.com/api/order', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Submission failed:', errorData);
                throw new Error(`HTTP ${response.status}: ${JSON.stringify(errorData)}`);
            }
    
            const result = await response.json();
            console.log('Success Response:', result);
    
            setSubmissionStatus('success'); // Success
            setFormData({ name: '', phone: '', service: '', message: '' }); // Clear form
        } catch (error) {
            setSubmissionStatus('error'); // Error
            console.error('Error submitting form:', error);
        }
    };
    
        
    
    

    return (
        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className='contact-card'>
            <button className="close-button" onClick={onClose}>&times;</button>
            <img className='contact-decor-1' src='/assets/images/contact-decor-1.png' alt='contact-icon' />
            <img className='contact-decor-2' src='/assets/images/contact-decor-2.png' alt='contact-icon' />

            <div className='contact-card-container'>
                <div className='contact-card-left'>
                    <img src='/assets/images/Mask group bw.png' alt='mask-group' />
                </div>
                <div className='contact-card-right'>
                    <div className='contact-card-right-top'>
                        <hr />
                        <h2>{t("ORDER A SERVICE")}</h2>
                        <p className='order-select-text'>{t("Select what best describes you")}</p>
                        <div className='user-supplier-selector'>
                            <StrokedButton
                                id={toggle === 'user' ? 'toggle-active' : 'toggle-inactive'}
                                placeHolder={t('User')}
                                onClick={handleToggleUser}
                            />
                            <StrokedButton
                                id={toggle === 'supplier' ? 'toggle-active' : 'toggle-inactive'}
                                placeHolder={t('Supplier')}
                                onClick={handleToggleSupplier}
                            />
                        </div>
                    </div>

                    {toggle === 'user' ? (
                        <form className='contact-card-right-form' onSubmit={handleSubmit}>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder={t("Your name")}
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                placeholder={t("Your phone number")}
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="service">{t("Choose a service:")}</label>
                            <select
                                id="service"
                                name="service"
                                value={formData.service}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="" disabled>{t("Select an option")}</option>
                                <option value="2">{t("Facility Maintenance Services")}</option>
                                <option value="3">{t("Cleaning and Sanitation")}</option>
                                <option value="4">{t("Electrical and Mechanical Services")}</option>
                                <option value="5">{t("HVAC Services")}</option>
                                <option value="6">{t("Plumbing and Water Systems")}</option>
                                <option value="7">{t("Building Management Services")}</option>
                                <option value="8">{t("Landscaping and Outdoor Maintenance")}</option>
                                <option value="9">{t("Specialized Services")}</option>
                            </select>
                            <SubmitButton placeHolder={t('Submit')} type='submit' />
                        </form>
                    ) : (
                        <form className='contact-card-right-form' onSubmit={handleSubmit}>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder={t("Your company name")}
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                placeholder={t("Your phone number")}
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                            <textarea
                                id="message"
                                name="message"
                                placeholder={t("Your message...")}
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                            />
                            <SubmitButton placeHolder={t('Submit')} type='submit' />
                        </form>
                    )}

                    {/* Display submission status */}
                    {submissionStatus === 'success' && (
                        <p className="success-message">{t("Thank you! Your request has been submitted.")}</p>
                    )}
                    {submissionStatus === 'error' && (
                        <p className="error-message">{t("Something went wrong. Please try again.")}</p>
                    )}
                </div>
            </div>
        </div>
    );
}