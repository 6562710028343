import React, { useState, useEffect } from 'react';


import './About.css';
import i18n from '../../i18n'

import { useTranslation } from 'react-i18next';


import AboutCard from '../../components/cards/AboutCard';
import OurClients from '../../components/our-clients/OurClients';
import ContactCard from '../../components/cards/ContactCard';

// API URL
const API_URL = "https://admin.proserveme.com/api/about";

const About = () => {
  const { t } = useTranslation();
  const [about, setAbout] = useState(null);

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const response = await fetch(API_URL);
        const result = await response.json();
        if (result.value) {
          setAbout(result.data);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchAbout();
  }, []);

  
  return (
    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className='main' >
      <div className='black-col'></div>
      {about && (
        <div className='who-are-we-section'>
        <img className='orange-dots-rectangle-about-1' src='/assets/images/Orange Dots Rectangle.png' alt='triangles-right'/>
        <img className='orange-dots-rectangle-about-2' src='/assets/images/Orange Dots Rectangle.png' alt='triangles-right'/>
        <img className='triangles-right' src='/assets/images/triangles-right.png' alt='triangles-right'/>
        <img className='who-are-we-left' src='/assets/images/who-are-we.jpg' alt='cctv'/>
        <div className='who-are-we-right'>
          <hr/>
          <h2 dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.about.title_ar : about.about.title_en }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.about.details_ar : about.about.details_en }} />
          </div>
      </div>
      )}
      
       {/* OUR APPROACH SECTION*/}
       {about && (
              <div className='our-approach-section'>
              <img className='orange-dots-rectangle-about-3' src='/assets/images/Orange Dots Rectangle.png' alt='triangles-right'/>
                    <div className='our-approach-card'>
                    <div className='our-approach-title'>
                      <hr/>
                      <h2 dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.our_approach.title_ar : about.our_approach.title_en }} />
                    </div>
                    <div className='our-approach-description'>
                    <div dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.our_approach.details_ar : about.our_approach.details_en }} />
                    </div>
                    </div>
                  </div>
        
       )}

              {/* MISSION VISION SECTION*/}
    {about && (
      <div className='home-about-section'>
      <img className='orange-dots-rectangle-about-4' src='/assets/images/Orange Dots Rectangle.png' alt='triangles-right'/>
          <div className='home-about-section-title'>
          </div>
          <hr className='texthr'></hr>
          <div className='home-about-section-cards'>
          <AboutCard 
            image={about.our_vision.image} 
            title={i18n.language === 'ar' ? about.our_vision.title_ar : about.our_vision.title_en} 
            description={i18n.language === 'ar' ? about.our_vision.details_ar : about.our_vision.details_en}/>

          <AboutCard 
            image={about.our_mission.image} 
            title={i18n.language === 'ar' ? about.our_mission.title_ar : about.our_mission.title_en} 
            description={i18n.language === 'ar' ? about.our_mission.details_ar : about.our_mission.details_en}/>

          <AboutCard 
            image={about.our_experience.image} 
            title={i18n.language === 'ar' ? about.our_experience.title_ar : about.our_experience.title_en} 
            description={i18n.language === 'ar' ? about.our_experience.details_ar : about.our_experience.details_en}/>

          <img className='spring-decore-1' src='/assets/images/spring-decore.png' alt='decore'/>
          </div>
          
        </div>

              )}

                  {/* GOALS VALUES SECTION*/}
        {about && (
        <div className='goals-values-section'>
        <img className='orange-dots-rectangle-about-5' src='/assets/images/Orange Dots Rectangle.png' alt='orange-dots-rectangle'/>
        <img className='orange-dots-rectangle-about-6' src='/assets/images/Orange Dots Rectangle.png' alt='orange-dots-rectangle'/>
        <img className='triangle-image' src='/assets/images/triangle-image.png' alt='triangle-image'/>
        <div className='goals-values-content'>
          <div className='goals'>

            <h2 dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.our_goal.title_ar : about.our_goal.title_en }} />
            <div dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.our_goal.details_ar : about.our_goal.details_en }} />
            </div>
          <div className='values'>
            <h2 dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.our_value.title_ar : about.our_value.title_en }} /> 
            <div dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? about.our_value.details_ar : about.our_value.details_en }} />
          </div>
        </div>
        </div>

        )}

          {/* OUR CLIENTS SECTION*/}
          <div className='our-clients-section'>
          <OurClients/>
          </div>
          <div className='home-contact-section'>
            <div className='conclusion-section'>
              <img className='quote' src='/assets/icons/Quote.svg' alt='quote'/>
              <h3>{t("In conclusion 1")} <br/><br/> 
              <strong>{t("In conclusion 2")}</strong></h3>
            </div>
            <ContactCard/>
          </div>


    </div>
  );
};

export default About;
