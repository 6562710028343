import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';

import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Services from './pages/services/Services';
import NotFound from './pages/notFound/NotFound';
import { useTranslation } from 'react-i18next';


const App = () => {
  const { i18n } = useTranslation();

  // Update the <body> dir attribute when the language changes
  useEffect(() => {
    document.body.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  return (
    <BrowserRouter>
      <div className="app" >

      <Nav />

      {/* Define Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
      </Routes>
      <Footer />
    </div>
    </BrowserRouter>
  );
};

export default App;
