import React, { useState, useEffect } from 'react';
import i18n from '../../i18n'
import './Cards.css';


// API URL
const API_URL = "https://admin.proserveme.com/api/home-services";

 export default function ServicesCard(){
    const [services, setServices] = useState([]);

    useEffect(() => {
        const fetchServices = async () => {
          try {
            const response = await fetch(API_URL);
            const result = await response.json();
            if (result.value) {
              setServices(result.data);
            }
          } catch (error) {
            console.error("Error fetching services:", error);
          }
        };
    
        fetchServices();
      }, []);
    
    return (
        services && (services.map(service => (
            <div className='services-card'>
            <img src={service.image} alt='service-icon'/>
            <h3>{i18n.language === 'ar' ? service.title_ar : service.title_en}</h3>
            <div dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? service.details_ar : service.details_en }} />
        </div>

        )))
    );

};
