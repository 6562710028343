import React, { useState, useEffect } from 'react';
import i18n from '../../i18n'
import './Cards.css';

// API URL
const API_URL = "https://admin.proserveme.com/api/setting";

 export default function AreasWeServeCard(){
    const [settings, setSettings] = useState(null);  // New state for settings

useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(API_URL);
        const result = await response.json();
        if (result.value) {
            setSettings(result.data);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

    return (
        settings && (        
        <div className='areas-we-serve-card'>
            <hr/>
            <h2 dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? settings.we_serve.title_ar : settings.we_serve.title_en }} />
            <div className='areas-we-serve-card-img-div'><img src={settings.we_serve.image} alt='ksa'/></div>
            <img className='quote' src='/assets/icons/Quote.svg' alt='quote'/>
            <div dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? settings.we_serve.details_ar : settings.we_serve.details_en }} />

        </div>
)
    );

};
