import React, { useState } from 'react';
import './Cards.css';
import { Link } from 'react-router-dom';
import SubmitButton from '../buttons/SubmitButton';
import { useTranslation } from 'react-i18next';

export default function ContactCard() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        message: ''
    });
    const [submissionStatus, setSubmissionStatus] = useState(null); // To track success or error

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        try {
            const response = await fetch('https://admin.proserveme.com/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();

            if (response.ok) {
                setSubmissionStatus('success'); // Success
                setFormData({ email: '', phone: '', message: '' }); // Clear form
            } else {
                setSubmissionStatus('error'); // Error
                console.error('Submission failed:', result);
            }
        } catch (error) {
            setSubmissionStatus('error'); // Error
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className='contact-card' id='contact-card'>
            <img className='contact-icon' src='/assets/images/contact-icon.png' alt='contact-icon' />
            <img className='contact-decor-1' src='/assets/images/contact-decor-1.png' alt='contact-icon' />
            <img className='contact-decor-2' src='/assets/images/contact-decor-2.png' alt='contact-icon' />

            <div className='contact-card-container'>
                <div className='contact-card-left'>
                    <img src='/assets/images/Mask group.png' alt='mask-group' />
                </div>
                <div className='contact-card-right'>
                    <div className='contact-card-right-top'>
                        <hr />
                        <h2>{t("CONTACT US")}</h2>
                        <p>
                            {t("Send us an email at ")}
                            <Link to='mailto:info@proserveme.com'>info@proserveme.com</Link>
                            <br />
                            {t("or simply fill the following form:")}
                        </p>
                    </div>
                    <form className='contact-card-right-form' onSubmit={handleSubmit}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder={t("Your email address")}
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="tel" // Use "tel" for phone numbers
                            id="phone"
                            name="phone"
                            placeholder={t("Your phone number")}
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            id="message"
                            name="message"
                            placeholder={t("Your message...")}
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                        />
                        <SubmitButton placeHolder={t('Submit')} type='submit' />
                    </form>

                    {/* Display submission status */}
                    {submissionStatus === 'success' && (
                        <p className="success-message">{t("Thank you! Your message has been sent and we will get back to you as soon as possible")}</p>
                    )}
                    {submissionStatus === 'error' && (
                        <p className="error-message">{t("Something went wrong. Please try again.")}</p>
                    )}
                </div>
            </div>
        </div>
    );
}