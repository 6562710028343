import React, { useState, useEffect } from 'react';
import './OurClients.css';
import { useTranslation } from 'react-i18next';


// API URL
const API_URL = "https://admin.proserveme.com/api/clients";

const OurClients = () => {
  const { t } = useTranslation();
  const [clients, setClients] = useState([]);
useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(API_URL);
        const result = await response.json();
        if (result.value) {
          setClients(result.data);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);

  return (
    clients && (
    <div className='our-clients'>
        <div className='home-services-section-title'>
            <h2>{t("OUR CLIENTS")}</h2>
        </div>
        <div className='our-clients-logos'>
          {clients.map(client => (
            <img key={client.id} src={client.logo} alt={client.id} href={client.link}/>
          ))}
          </div>
          </div>

    )
);
};

export default OurClients;
