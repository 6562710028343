import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';

// Get the saved language from localStorage or use a default
const savedLanguage = localStorage.getItem('language') || 'en';

// Set the <body> dir attribute on initial load
document.body.dir = savedLanguage === 'ar' ? 'rtl' : 'ltr';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
    },
    lng: savedLanguage, // Set the initial language from localStorage
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;