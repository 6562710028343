import React, { useState, useEffect } from 'react';
import './Services.css';
import i18n from '../../i18n'



//BUTTONS AND CARDS
import ServicesCard from '../../components/cards/ServicesCard';
import AreasWeServeCard from '../../components/cards/AreasWeServeCard';
import ContactCard from '../../components/cards/ContactCard';
import ChooseServiceCard from '../../components/cards/ChooseServiceCard';
import { useTranslation } from 'react-i18next';


// API Base URL
const API_URL = "https://admin.proserveme.com/api/services";

const Services = () => {
  const { t } = useTranslation();
  const [services, setServices] = useState([]);

useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(API_URL);
        const result = await response.json();
        if (result.value) {
          setServices(result.data);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

        
      
  return (
    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className='main'>
    {/* OUR SERVICES SECTION*/}
      <div className='home-services-section'>
      <img className='triangles-1' src='/assets/images/Triangles.png' alt='decore'/>
      <img className='orange-dots-rectangle-2' src='/assets/images/Orange Dots Rectangle.png' alt='decore'/>
        <div className='home-services-section-title'>
          <p>{t("TAKE A LOOK AT")}</p>
          <h2>{t("OUR SERVICES")}</h2>
        </div>
        <div className='home-services-section-cards'>
            <ServicesCard/>
      </div>
      </div>
      {/* CHOOSE A SERVICE SECTION*/}
      <div className='choose-services-section'>
        <hr/>
        <h2>{t("CHOOSE A ")}<strong>{t("SERVICE")}</strong></h2>
        <div className='choose-sercvice-cards'>
        {services.map(service => (
            <ChooseServiceCard 
              key={service.id}
              title={i18n.language === 'ar' ? service.title_ar : service.title_en}
              description={i18n.language === 'ar' ? service.details_ar : service.details_en}
              serviceId={service.id} 
            />
          ))}

        </div>
      </div>
      {/* AREAS WE SERVE SECTION*/}
      
        <div className="settings-section">
<div className='home-areas-we-serve-section'>
        <img className='orange-dots-rectangle-3' src='/assets/images/Orange Dots Rectangle.png' alt='decore'/>
        <img className='orange-dots-rectangle-4' src='/assets/images/Orange Dots Rectangle.png' alt='decore'/>
        <img src='/assets/images/orange-shadow-circle.png' alt='circle' className='orange-shadow-circle'/>   
        <AreasWeServeCard/>

        </div>
        </div>
      
      {/* CONTACT SECTION*/}
      <div className='home-contact-section'>
        <ContactCard/>
      </div>
    </div>
  );
};

export default Services;
