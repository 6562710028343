import React, { useState, useEffect } from 'react';
import './Home.css';
import {  Link } from 'react-router-dom';
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next';

//BUTTONS AND CARDS
import InfoButton from '../../components/buttons/InfoButton';
import SubmitButton from '../../components/buttons/SubmitButton';
import ServicesCard from '../../components/cards/ServicesCard';
import AboutCard from '../../components/cards/AboutCard';
import AreasWeServeCard from '../../components/cards/AreasWeServeCard';
import OurClients from '../../components/our-clients/OurClients';
import ContactCard from '../../components/cards/ContactCard';
import OrderCard from '../../components/cards/OrderCard';

// API URL
const API_BASE_URL = "https://admin.proserveme.com/api";

const Home = () => {
  const { t } = useTranslation();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [about, setAbout] = useState(null);
  const [settings, setSettings] = useState(null);

  const handleOrderClick = () => {
    setShowOrderModal(true);
  };
  const handleCloseModal = () => {
    setShowOrderModal(false);
  };

useEffect(() => {
    // Fetch all APIs in parallel
    Promise.all([
      fetch(`${API_BASE_URL}/about`).then(res => res.json()),
      fetch(`${API_BASE_URL}/setting`).then(res => res.json())
    ])
    .then(([aboutData, settingsData]) => {
      if (aboutData.value) setAbout(aboutData.data);
      if (settingsData.value) setSettings(settingsData.data); // Store settings data
    })
    .catch(error => console.error("Error fetching data:", error));
  }, []);




  return (
    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className='main'>
      {/* HOME DECORE*/}
      <img className='decore-triangle' src='/assets/images/Decore-triangle.png' alt='decore'/>
      <img className='orange-dots-rectangle-1' src='/assets/images/Orange Dots Rectangle.png' alt='decore'/>
      {/* HERO SECTION */}
      {settings && (
        <div className='hero-section'>
        <div className='hero-section-text'>
        <hr/>
          <h1 dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? settings.banner.title_ar : settings.banner.title_en }} />
          
          <div dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' ? settings.banner.details_ar : settings.banner.details_en }} />
          <div className='hero-section-buttons'>
          <Link to='/about' onClick={() => {window.scrollTo(0, 0);}}> <InfoButton placeHolder={t('Find Out More')}/> </Link>
            <SubmitButton placeHolder={t('Order Now')} onClick={handleOrderClick}/>
        </div>
        </div>
        <picture>
          {/* Default image for screens wider than 1200px */}
          <source srcSet={i18n.language === 'ar' ?"/assets/images/van-full.png":settings.banner.image} media="(min-width: 1200px)" />
          {/* Alternative image for screens narrower than 1200px */}
          <source srcSet={settings.banner.image} media="(max-width: 1199px)" />
          {/* Fallback image */}
          <img
            className="hero-image"
            src={settings.banner.image}
            alt="van"
          />
        </picture>
      </div>
      )}
      
      {/* HOME DECORE*/}
    {/* OUR SERVICES SECTION*/}
    <div className='home-services-section'>
    <img className='triangles-1' src='/assets/images/Triangles.png' alt='decore'/>
    <img className='orange-dots-rectangle-2' src='/assets/images/Orange Dots Rectangle.png' alt='decore'/>
      <div className='home-services-section-title'>
        <h2>{t('OUR SERVICES')}</h2>
      </div>
      <div className='home-services-section-cards'>
            <ServicesCard />

      </div>
      <Link to='/services' onClick={() => {window.scrollTo(0, 0);}}> <InfoButton placeHolder={t('Show All Services')}/> </Link>
    </div>
          {/* HOME DECORE*/}
{/* MISSION VISION SECTION*/}
{about && (
      <div className='home-about-section'>
      <img className='orange-dots-rectangle-about-4' src='/assets/images/Orange Dots Rectangle.png' alt='triangles-right'/>
          <div className='home-about-section-title'>
          </div>
          <hr className='texthr'></hr>
          <div className='home-about-section-cards'>
          <AboutCard 
            image={about.our_vision.image} 
            title={i18n.language === 'ar' ? about.our_vision.title_ar : about.our_vision.title_en} 
            description={i18n.language === 'ar' ? about.our_vision.details_ar : about.our_vision.details_en}/>

          <AboutCard 
            image={about.our_mission.image} 
            title={i18n.language === 'ar' ? about.our_mission.title_ar : about.our_mission.title_en} 
            description={i18n.language === 'ar' ? about.our_mission.details_ar : about.our_mission.details_en}/>

          <AboutCard 
            image={about.our_experience.image} 
            title={i18n.language === 'ar' ? about.our_experience.title_ar : about.our_experience.title_en} 
            description={i18n.language === 'ar' ? about.our_experience.details_ar : about.our_experience.details_en}/>

          <img className='spring-decore-1' src='/assets/images/spring-decore.png' alt='decore'/>
          </div>
          <Link to='/about' onClick={() => {window.scrollTo(0, 0);}}> <InfoButton placeHolder={t('Learn More')}/> </Link>

        </div>

              )}

          {/* AREAS WE SERVE SECTION*/}
          <div className='home-areas-we-serve-section'>
          <img className='orange-dots-rectangle-3' src='/assets/images/Orange Dots Rectangle.png' alt='decore'/>
          <img className='orange-dots-rectangle-4' src='/assets/images/Orange Dots Rectangle.png' alt='decore'/>
            <img src='/assets/images/orange-shadow-circle.png' alt='circle' className='orange-shadow-circle'/>
            <AreasWeServeCard/>
          </div>
          {/* OUR CLIENTS SECTION*/}
          <div className='our-clients-section'>
          <OurClients/>
          </div>
          {/* CONTACT SECTION*/}
          <div className='home-contact-section'>
            <ContactCard/>
          </div>
          {showOrderModal && (
                      <div className="modal-backdrop" onClick={handleCloseModal}>
                          <div className="modal-content" onClick={e => e.stopPropagation()}>
                              <OrderCard onClose={handleCloseModal} />
                          </div>
                      </div>
                  )}
    </div>
  );
};

export default Home;
