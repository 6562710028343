import React, { useState } from 'react';import './Cards.css';
import './Cards.css';
import i18n from '../../i18n'



import StrokedButton from '../buttons/StrokedButton';
import OrderCard from '../cards/OrderCard';
import { useTranslation } from 'react-i18next';


 export default function ChooseServiceCard({title, description , serviceId }){
    const [toggle, setToggle] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const { t } = useTranslation();

    const handleOrderClick = () => {
        setShowOrderModal(true);
    };

    const handleCloseModal = () => {
        setShowOrderModal(false);
    };

    return (
        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className='choose-service-card'>
                <div className='choose-service-card-title' onClick={()=>setToggle(!toggle)}><h3 className={toggle?'active-title':'inactive-title'}>{title}</h3><img src={toggle? '/assets/icons/arrow-up.svg': '/assets/icons/arrow-down.svg'} alt='arrow'/></div>
                <div className={toggle?'choose-service-card-description-visible':'choose-service-card-description-hidden'}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
                <StrokedButton placeHolder={t('Choose Service')} onClick={handleOrderClick}/></div>        
                {showOrderModal && (
                            <div className="modal-backdrop" onClick={handleCloseModal}>
                                <div className="modal-content" onClick={e => e.stopPropagation()}>
                                    <OrderCard onClose={handleCloseModal} preSelectedServiceId={serviceId}/>
                                </div>
                            </div>
                        )}  
        </div>
    );

};
